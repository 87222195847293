import { ThemeSelectors } from "@at/design-system-foundation";
import styled, { css } from "styled-components";

import { PrintStyleSheet } from "../../components/seller/advert-seller-details/location/location.styles";

export const LinkBorderedContainer = styled.div`
  ${({ theme }) => {
    const gridUnit = ThemeSelectors.getGridUnit(theme);
    const colorGrey = ThemeSelectors.getLegacyGrey(theme);

    return css`
      position: relative;
      border-top: 1px solid ${colorGrey};
      border-bottom: 1px solid ${colorGrey};
      margin: calc(${gridUnit} * 2) 0;
      ${PrintStyleSheet}

      & + & {
        margin-top: calc(${gridUnit} * -2);
        border-top-color: transparent;
      }
    `;
  }}
`;
