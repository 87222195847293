import { Link } from "@at/design-system-elements";
import { TypeFiesta, ThemeSelectors } from "@at/design-system-foundation";
import styled from "styled-components";

import { PrintStyleSheet } from "../../seller/advert-seller-details/location/location.styles";

export const PriceDetailLine = styled.div<{
  borderTop?: boolean;
  boldPrice?: boolean;
  borderBottom?: boolean;
}>`
  display: flex;
  align-items: center;
  margin: 0 0 calc(${({ theme }) => ThemeSelectors.getGridUnit(theme)} / 2);
  width: 100%;
  font-size: 12px;
  a {
    border: none;
  }
  div:first-child {
    margin-right: 4px;
  }

  div:not(:first-child),
  button:not(:first-child) {
  }

  svg {
    ${PrintStyleSheet}
  }

  div:not(:first-child) {
    font-weight: ${({ boldPrice, theme }) =>
      boldPrice ? ThemeSelectors.getMediumFontWeight(theme) : ""};
    font-variation-settings: "wght"
      ${({ boldPrice, theme }) =>
        boldPrice ? ThemeSelectors.getMediumFontWeight(theme) : ""};
  }

  padding-top: ${({ theme }) => ThemeSelectors.getGridUnit(theme)};
  padding-bottom: ${({ borderBottom, theme }) =>
    borderBottom ? ThemeSelectors.getGridUnit(theme) : "0px"};

  border-bottom: 1px solid
    ${({ theme, borderBottom }) =>
      borderBottom ? ThemeSelectors.getLegacyGrey(theme) : "white"};

  @media print {
    border: none;
  }
`;
export const AdminFeeWording = styled(TypeFiesta).attrs({
  as: "li",
  weight: "400",
})`
  border-radius: ${({ theme }) => ThemeSelectors.getGroupOneSpacing(theme)};
  display: inline-flex;
  padding-right: ${({ theme }) => ThemeSelectors.getGroupOneSpacing(theme)};
  color: ${({ theme }) => ThemeSelectors.getLegacyBlue(theme)};
  margin: ${({ theme }) => ThemeSelectors.getGroupTwoSpacing(theme)} 0 0 0;
`;

export const AdminFees = styled(Link).attrs({
  renderAsButton: true,
})`
  border: none;
  align-items: center;
  line-height: 18px;
  margin: 0 0 calc(${({ theme }) => ThemeSelectors.getGridUnit(theme)} / 2);
  display: inline-block;

  svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    fill: currentColor;
    ${PrintStyleSheet}
  }
`;

export const AdminFeesModalContent = styled(TypeFiesta).attrs({ as: "p" })`
  padding: 0;
  margin-bottom: 12px;
`;

export const AdminFeesContainer = styled.ul`
  display: block;
  align-items: flex-start;
`;
