import { ThemeSelectors } from "@at/design-system-foundation";
import { BREAKPOINTS } from "@at/window-utils/lib/responsive-utils/match-media.util";
import styled from "styled-components";

export const AdvertImageSection = styled.section`
  position: relative;
  flex-basis: 100%;
  @media screen and (min-width: 991px) {
    flex-basis: 60%;
  }
  @media print {
    margin: 0;
    flex-basis: 60%;
  }
`;

export const IllustrativeOnlyDisclaimer = styled.span`
  display: block;
  font-size: 14px;
  ${({ theme }) => `
        padding-bottom: calc(${ThemeSelectors.getGridUnit(theme)} * 2);
        @media screen and (max-width: ${BREAKPOINTS.MEDIUM}px) {
            margin-left calc(${ThemeSelectors.getGridUnit(theme)} * 2);
        }
    `}
`;
