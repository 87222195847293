import { LinkStyles } from "@at/design-system-elements";
import { TypeFiesta, ThemeSelectors } from "@at/design-system-foundation";
import { BREAKPOINTS } from "@at/window-utils/lib/responsive-utils/match-media.util";
import styled, { css } from "styled-components";

import { ResetButtonStyles } from "../../utils/styles/buttons.styles";
import { LinkBorderedContainer } from "../../utils/styles/link.styles";
import { MobilePadding } from "../advert/advert-overview.styles";
import { PrintStyleSheet } from "../seller/advert-seller-details/location/location.styles";

export const AdvertHeadingSectionStyled = styled.section`
  ${MobilePadding}
  ${({ theme }) => css`
    margin: ${ThemeSelectors.getGridUnit(theme)} 0
      calc(${ThemeSelectors.getGridUnit(theme)} * 3);
    position: relative;
  `}
`;

export const AdvertConditionStyled = styled.p`
  ${({ theme }) => css`
    margin: 0 0 ${ThemeSelectors.getGridUnit(theme)};
    color: ${ThemeSelectors.getLegacyBlue(theme)};
    display: block;
  `}
`;

export const AdvertPriceStyled = styled.h2<{ withSaving: boolean }>`
  ${({ theme, withSaving }) => {
    const gridUnit = ThemeSelectors.getGridUnit(theme);
    const colorBlue = ThemeSelectors.getLegacyBlue(theme);
    const colorRed = ThemeSelectors.getATRed(theme);

    return css`
      margin: ${withSaving ? 0 : `calc(${gridUnit} / 2) 0 0`};
      color: ${withSaving ? colorRed : colorBlue};
      font-size: 40px;
      line-height: 1.25;
      margin-top: ${({ theme }) => ThemeSelectors.getGroupThreeSpacing(theme)};
      font-weight: ${({ theme }) => ThemeSelectors.getMediumFontWeight(theme)};
      font-variation-settings: "wght"
        ${({ theme }) => ThemeSelectors.getMediumFontWeight(theme)};
      font-family: ${({ theme }) => ThemeSelectors.getTypeFontName(theme)},
        ${({ theme }) => ThemeSelectors.getDefaultTypeStack(theme)};
      @media print {
        color: #000000;
      }
    `;
  }}
`;

export const AdvertPriceConfidence = styled.div`
  ${({ theme }) => {
    const gridUnit = ThemeSelectors.getGridUnit(theme);

    return css`
      display: flex;
      padding: 0;
      margin: 0;
      flex-flow: row nowrap;
      justify-content: center;
      line-height: 1;

      & + ${LinkBorderedContainer} {
        margin-top: calc(${gridUnit} * -2);
        border-top-color: transparent;
      }
    `;
  }}
`;

export const AdvertPriceConfidenceMain = styled.div`
  flex: 1 1 auto;
`;

export const AdvertPriceConfidenceAside = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: flex-end;
`;

export const RRP = styled(TypeFiesta).attrs({ as: "span", weight: 500 })`
  ${({ theme }) => css`
    color: ${ThemeSelectors.getLegacyCopyLightColor(theme)};
  `}
`;
export const Saving = styled.div`
  display: flex;
  margin-top: ${({ theme }) => ThemeSelectors.getGroupOneSpacing(theme)};
`;

export const RRPValue = styled.span`
  text-decoration: line-through;
`;

export const Save = styled(TypeFiesta).attrs({ as: "span", weight: 500 })`
  ${({ theme }) => css`
    color: ${ThemeSelectors.getATRed(theme)};
    margin-left: 18px;
    margin-top: 0;
    &.pre-reg {
      margin-left: 0;
    }
    @media print {
      color: #000000;
    }
  `}
`;

export const PreRegWrapper = styled.span`
  display: flex;
  align-items: center;
`;

export const InfoButtonStyled = styled(ResetButtonStyles)`
  ${({ theme }) => LinkStyles(theme)};
  border: none;
  padding-left: ${({ theme }) => ThemeSelectors.getGroupOneSpacing(theme)};
  display: flex;
  align-items: center;
  justify-content: center;
  ${PrintStyleSheet}

  svg,
  path {
    fill: currentColor;
    position: relative;
  }
`;

export const AdvertImportedVehicle = styled.div`
  ${({ theme }) => css`
    display: block;
    margin: calc(${ThemeSelectors.getGridUnit(theme)} * 2) 0;
  `}
`;

export const AdvertImportedVehicleTitle = styled(TypeFiesta).attrs({
  as: "h2",
  weight: 400,
})`
  ${({ theme }) => css`
    display: inline-block;
    margin: 0;
    padding: 0 ${ThemeSelectors.getGridUnit(theme)};
    border: 1px solid ${ThemeSelectors.getLegacyDarkYellow(theme)};
    border-radius: 2px;
  `}
`;

export const AdvertHeadingCtas = styled.ul`
  ${({ theme }) => {
    const gridUnit = ThemeSelectors.getGridUnit(theme);

    return css`
      display: none;
      margin: calc(${gridUnit} * 3) calc(${gridUnit} * -1);

      @media screen and (min-width: ${BREAKPOINTS.MEDIUM}px) {
        display: grid;
        gap: 16px;
      }

      li {
        margin: 0 ${gridUnit};
      }

      .atds-button {
        @media screen and (min-width: ${BREAKPOINTS.MEDIUM}px) {
          width: 100%;
        }
        &:focus {
          color: ${({ theme }) => ThemeSelectors.getWhite(theme)};
        }
      }
    `;
  }}
`;
