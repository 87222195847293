import { ThemeSelectors, TypeSmart } from "@at/design-system-foundation";
import styled from "styled-components";

import { isUserInRedButtonTest } from "../../utils/ab-test/ab-test.util";

export const PillContainer = styled.ul`
  margin: ${({ theme }) => ThemeSelectors.getGroupThreeSpacing(theme)} 0
    ${({ theme }) => ThemeSelectors.getGroupFiveSpacing(theme)};
  display: block;
  align-items: flex-start;

  @media print {
    margin: ${({ theme }) => ThemeSelectors.getGroupTwoSpacing(theme)} 0 0;
  }
`;

export const Pill = styled(TypeSmart).attrs({ as: "li", weight: "500" })`
  border-radius: ${({ theme }) => ThemeSelectors.getGroupOneSpacing(theme)};
  display: inline-flex;
  padding: ${({ theme }) =>
    `${ThemeSelectors.getGroupOneSpacing(
      theme,
    )} ${ThemeSelectors.getGroupTwoSpacing(theme)}`};
  background: ${({ theme }) =>
    isUserInRedButtonTest
      ? ThemeSelectors.getLightGrey(theme)
      : ThemeSelectors.getColorDecorBgSurfaceSoft(theme)};
  color: ${({ theme }) => ThemeSelectors.getLegacyBlue(theme)};
  margin: ${({ theme }) => ThemeSelectors.getGroupTwoSpacing(theme)}
    ${({ theme }) => ThemeSelectors.getGroupThreeSpacing(theme)} 0 0;
  @media print {
    margin: 0;
    padding: 0 2px 0;

    &::after {
      content: ", ";
    }

    &:last-child::after {
      content: "";
    }
  }
`;
